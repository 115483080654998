import { FC, PropsWithChildren } from 'react';

import { Avatar, Flex, Space } from 'antd';

import { Icon } from '@assets/icon';
import { ProductAvatar } from '@ui/avatar/ProductAvatar';
import CustomAvatar, { AvatarSize } from '@ui/avatar/index';
import TextInfoBlock, { TextInfoBlockType } from '@ui/text-info-block';

export interface AvatarInfoProps extends TextInfoBlockType, PropsWithChildren {
  src?: string;
  alt?: string;
  isProduct?: boolean;
  isCalendar?: boolean;
  showImage?: boolean;
  descriptionColor?: string;
  alignAvatar?: 'center' | 'start' | 'end';
}
const AvatarInfo: FC<AvatarInfoProps> = ({
  src,
  alt,
  title,
  description,
  isProduct,
  descriptionColor,
  titleFontSize,
  children,
  alignAvatar,
  showImage = true,
  isCalendar,
}) => (
  <Space size={isCalendar ? 8 : 12} align={alignAvatar}>
    {showImage &&
      (isProduct ? (
        <ProductAvatar src={src} />
      ) : isCalendar ? (
        <Avatar src={src} size={32} icon={<Icon type={'user'} size={24} />} />
      ) : (
        <CustomAvatar src={src} size={AvatarSize.Large} alt={alt} />
      ))}
    <Flex vertical gap={12}>
      <TextInfoBlock
        title={title}
        description={description}
        titleFontSize={titleFontSize ? titleFontSize : isProduct ? 14 : 12}
        descriptionColor={descriptionColor}
      />
      {children}
    </Flex>
  </Space>
);

export default AvatarInfo;
